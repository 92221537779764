'use strict';
import angular from 'angular';
import _ from 'lodash';
import { AppModule } from 'coreModules/shared/scripts/app.constants';
import {LoadingState, WidgetData, WidgetType, WidgetTypeGrouping} from 'coreModules/design/widget/design.widget.constants';
import { DateUtil } from '@/modules/core/app/utils/DateUtil';

angular.module('design.widget.ctrls', [])

    .controller('WidgetLoadedController', WidgetLoadedController)
    .controller('WidgetLoaderController', WidgetLoaderController)
    .controller('WidgetController', WidgetController)
    .controller('WidgetDataDisplayController', WidgetDataDisplayController);

/**
 * @ngInject
 */
function WidgetLoadedController(
    $scope,
    AppFactory,
    ChartUtilFactory,
    WidgetUtilService,
    DesignFactory,
    WidgetFactory,
    WidgetBuilderUIService,
    DashboardFilterPanelFactory,
    DrawOption,
    ExportFactory,
    DataSourceFactory,
    PageEntity,
    DataSourceType,
    ReportStudioTemplateDataService,
    LiveIntegrationFactory,
    DateRangeFactory,
    DashboardContextService,
    RelativeDateRange,
) {
    $scope.maxDataPoints = WidgetData.MAX_RESULTS;
    $scope.DataSourceType = DataSourceType;
    $scope.LoadingState = LoadingState;
    $scope.DrawOption = DrawOption;
    $scope.user = AppFactory.getUser();
    $scope.showReportingProfileLink = false;
    $scope.isExporting = DesignFactory.getIsExportingPage();
    $scope.io = $scope.user.isIORoute;
    $scope.isCreatingWidget = WidgetBuilderUIService.isActive();
    $scope.isSample = WidgetFactory.useSampleData($scope.widget.metadata);
    $scope.showWidgetTypeIcon = DesignFactory.getCurrentPage().entity !== PageEntity.PAGE_OVERVIEW;
    $scope.isMapboxWidget = $scope.widget.metadata.draw_options.plot_type === WidgetType.MAPBOX;
    $scope.setWidgetCustomDateRangeText = setWidgetCustomDateRangeText;

    if (!$scope.isCreatingWidget) {
        $scope.showReportingProfileLink = AppFactory.getUser().isAdmin();
        // If we show the data profile link, build link to lead to the right place
        if ($scope.showReportingProfileLink && $scope.widget.metadata.data_source) {
            $scope.currentReportingProfileId = AppFactory.getUser().reportingProfileId;
            $scope.currentDataSourceId = $scope.widget.metadata.data_source.id;
        }
    }

    if ($scope.widget.metadata.dynamic && $scope.widget.metadata.dynamic.is_inactive) {
        $scope.inactiveEntity = null;
        $scope.inactivePrefix = '';
        if ($scope.isExporting) {
            $scope.inactiveEntity = ExportFactory.getVars().reportVars.report_descriptor;
            if (_.isEmpty($scope.inactiveEntity)) {
                $scope.inactivePrefix = ' this widget';
            }
        }
        else {
            var selectedEntity;
            if ($scope.widget.layout_id) {
                selectedEntity = DesignFactory.getCurrentPage().metadata.selected_entity;
            } else {
                selectedEntity = ReportStudioTemplateDataService.getEntity();
            }

            if (!_.isNil(selectedEntity)) {
                if (selectedEntity.type === DataSourceType.CLIENT) {
                    $scope.inactiveEntity = selectedEntity.name || selectedEntity.text;
                } else if ($scope.widget.report_id  && (selectedEntity.type === DataSourceType.CLIENT_GROUP || selectedEntity.type === DataSourceType.CLUSTER)) {
                    $scope.inactiveEntity = selectedEntity.text;
                    $scope.inactivePrefix = ' any clients in ';

                }
            } else if ($scope.widget.layout_id && !_.isEmpty(DashboardFilterPanelFactory.shared.filters)) {
                _.each(DashboardFilterPanelFactory.shared.filters, function (filter, key) {
                    var values = filter.values;
                    if (values && !_.isEmpty(values)) {
                        $scope.inactiveEntity = values[0].text;
                        $scope.inactivePrefix = key === DataSourceType.CLIENT_GROUP || key === DataSourceType.CLUSTER
                            ? ' any clients in '
                            : '';
                        return false;
                    }
                });
            } else {
                if ($scope.user.isClusterAdmin()) {
                    $scope.inactiveEntity = $scope.user.clusterName;
                }
            }
        }
    }

    $scope.fnIsExporting = function() {
        return ExportFactory.getIsExporting();
    };

    $scope.canIncludeProbeToggler = function() {
        return !$scope.isSample
            && !$scope.isCreatingWidget
            && !$scope.io
            && !$scope.isExporting
            && !$scope.user.isClient()
            && !$scope.widget.metadata.is_overriding_date_range
            && (DataSourceFactory.dataSourceContainsServices($scope.widget.metadata.data_source.type)
                || $scope.widget.metadata.data_source.type === DataSourceType.LEADS
                || $scope.widget.metadata.data_source.type === DataSourceType.GOAL_DATA
                || $scope.widget.metadata.data_source.type === DataSourceType.INDUSTRY_BENCHMARK_PAID_MEDIA
                || $scope.widget.metadata.data_source.type === DataSourceType.INDUSTRY_BENCHMARK_EMAIL
                || $scope.widget.metadata.data_source.type === DataSourceType.INDUSTRY_BENCHMARK_LEAD_TRACKING)
            && $scope.widget.metadata.data_source.type !== DataSourceType.SEO_WEBSITES
            && LiveIntegrationFactory.canIncludeProbeToggler($scope.widget); // Disable the toggler when live integrations are enabled.
    };

    // sets custom text when override date range is applied with dashboard filters
    function setWidgetCustomDateRangeText(widget) {
      const {
        is_overriding_date_range,
        end_date_override,
        start_date_override,
        relative_date_range,
      } = widget.metadata;
      if (
        is_overriding_date_range &&
        DashboardContextService.pageHasActiveFilter()
      ) {
        let start, end;
        if (relative_date_range === RelativeDateRange.CUSTOM) {
          start = DateUtil.formatDate(start_date_override);
          end = DateUtil.formatDate(end_date_override);
        } else {
          const relativeRangeDates =
            DateRangeFactory.getDateRangeFromRelativeRange(relative_date_range);
          start = DateUtil.formatDate(relativeRangeDates.start._i);
          end = DateUtil.formatDate(relativeRangeDates.end._i);
        }
        return `Range override applied from ${start} to ${end}`;
      }
    }
}

/**
 * @ngInject
 */
function WidgetController(
    $scope,
    $timeout,
    PubSub,
    WidgetUtilService,
    $WidgetEvents,
    DrawOption,
    AppFactory,
    DesignFactory,
    WidgetFactory,
    DrawOptionFactory,
    WidgetBuilderService,
    EditLayoutFactory,
    LayoutFactory,
    PageEvents
) {
    WidgetFactory.$registerScope($scope);

    $scope.getWidgetClass = getWidgetClass;
    $scope.dataStateShouldShowWidget = dataStateShouldShowWidget;
    $scope.canShowChatGptWidgets = canShowChatGptWidgets;
    $scope.canIncludeWidgetHeader = canIncludeWidgetHeader;
    $scope.isFromExportBuilder = $scope.isFromExportBuilder || false;
    $scope.getScrollStyle = $scope.isFromExportBuilder ? 'hide-scroll' : 'show-scroll';
    $scope.isChatGptWidgetEnabled = AppFactory.isUserModuleAvailable(AppModule.CHATGPT_WIDGET);
    $scope.hasChatGPTIntegration = AppFactory.isUserModuleAvailable(AppModule.CHATGPT);

    // Expose constant to scope
    $scope.WidgetType = WidgetType;
    $scope.WidgetTypeGrouping = WidgetTypeGrouping;
    $scope.$on('$destroy', _onDestroy);

    /**
     * Set user specific info
     */
    var user = AppFactory.getUser();
    $scope.showServiceDrilldowns = user.showServiceDrilldowns;

    var init = function() {
        _registerEvents();

        var widget = $scope.widget;
        PubSub.emit(PageEvents.PERFORMANCE_TRACKING, {
            event: $WidgetEvents.WIDGET_INITIATED,
            payload: {
                id: widget.id,
                type: widget.type
            }
        })


        // Avoid widget init if editing since state was already set in CreateWidgetController
        if (_.isUndefined($scope.state)) {
            $scope.state = WidgetFactory.getDefaultState();
        }
        WidgetFactory.setStateForWidgetType($scope.state, widget.type, widget.metadata);

        $scope.datasource = _.isEmpty(widget.metadata.data_source) ? null : widget.metadata.data_source;
        $scope.typeGrouping = WidgetFactory.getWidgetTypeGrouping(widget.type);
        $timeout(function() {
            $scope.state.isChangingWidgetType = false;
        });
    };

    init();

    /**
     * Style widget based on draw options and resolved classes
     * @returns {string}
     */
    function getWidgetClass() {
        var classNames = [];
        if (!_resolveDrawOptionValue(DrawOption.SHOW_BACKGROUND)) {
            classNames.push('transparent');
        }
        if (!_resolveDrawOptionValue(DrawOption.SHOW_BORDERS)) {
            classNames.push('transparent-border');
            classNames.push('no-shadow');
        }
        if ($scope.state.isAnnotating) {
            classNames.push('glow');
        }
        if ($scope.widget.height < 3) {
            classNames.push('tiny-height');
        }
        if (WidgetBuilderService.isCurrentWidgetInBuildMode($scope.widget.id)) {
            classNames.push('glow');
            $scope.state.isBuilding = true;
        } else {
            $scope.state.isBuilding = false;
        }
        $scope.state.isShowing = EditLayoutFactory.getIsEditPanelOpen();

        return classNames.join(' ');
    }

    function _onDestroy() {
        PubSub.off($WidgetEvents.SWAP_WIDGET + $scope.widget.id, _swapType);
    }

    /**
     * @private
     * Will resolve the appropriate draw options based on the layout/widget inheritance tree
     * @returns {*}
     */
    function _resolveDrawOptionValue(key) {
        return DrawOptionFactory.resolveDrawOptionValue(key, $scope.widget, $scope.widget.is_export);
    }

    function dataStateShouldShowWidget() {
        const showNoDataWidgets = _resolveDrawOptionValue(DrawOption.SHOW_WIDGETS_WITH_NO_DATA);
        const newShowValue = !(showNoDataWidgets
            || !(showNoDataWidgets || _loadedWidgetHasNoData($scope.state.loadingState))
            || !$scope.state.isDataSourcedWidget
            || WidgetBuilderService.getIsActive()
            || $scope.state.isQueryingDatagrid);
        if ($scope.widget.metadata.dynamic.shouldHide !== newShowValue) {
            $scope.widget.metadata.dynamic.shouldHide = newShowValue;
            LayoutFactory.updateLayoutWidgets();
        }
        return true;
    }

    function _loadedWidgetHasNoData(loadingState) {
        return loadingState === LoadingState.NO_RECORDS
            || loadingState === LoadingState.NO_DATA
            || loadingState === LoadingState.ALL_EMPTY_DATA
            || loadingState === LoadingState.NO_COLUMNS
            || loadingState === LoadingState.INACTIVE
            || loadingState === LoadingState.HAS_ERROR
            || loadingState === LoadingState.INCOMPLETE
            || loadingState === LoadingState.ERROR
            || loadingState === LoadingState.NO_DATA_ACTIONABLE;
    }

    /**
     *
     * @param widget
     * @private
     */
    function _swapType(widget) {
        // Need to swap the widget in DesignFactory
        DesignFactory.swapWidgetInCurrentLayout(widget.id, widget);
        $scope.widget = widget;
        WidgetFactory.setStateForWidgetType($scope.state, widget.type, widget.metadata);

        $scope.state.loadingState = null;
        $scope.state.isChangingWidgetType = true;
        $scope.datasource = _.isEmpty(widget.metadata.data_source) ? null : widget.metadata.data_source;
        $scope.typeGrouping = WidgetFactory.getWidgetTypeGrouping(widget.type);

        // metadata.predefined_data needs to be set to null, so that we can rebuild widgets when applying filters/date changes
        if (widget.is_predefined && widget.metadata.dynamic) {
            widget.metadata.dynamic.predefined_data = null;
        }

        $timeout(function() {
            $scope.state.isChangingWidgetType = false;
        });
    }

    function _registerEvents() {
        /**
         * This listener gets triggered for all widget types
         */
        $scope.$on($WidgetEvents.WIDGET_REBUILD, function () {
            var widget = $scope.widget;

            // Set the widget state whenever widget it's rebuild.
            WidgetFactory.setStateForWidgetType($scope.state, widget.type, widget.metadata);

            $scope.datasource = _.isEmpty(widget.metadata.data_source) ? null : widget.metadata.data_source;
            $scope.typeGrouping = WidgetFactory.getWidgetTypeGrouping(widget.type);

            // metadata.predefined_data needs to be set to null, so that we can rebuild widgets when applying filters/date changes
            if (widget.is_predefined && widget.metadata.dynamic && widget.type !== WidgetType.GOAL) {
                widget.metadata.dynamic.predefined_data = null;
            }
            $scope.state.isChangingWidgetType = false;

        });

        PubSub.on($WidgetEvents.SWAP_WIDGET + $scope.widget.id, _swapType);
    }

    function canShowChatGptWidgets() {
        return $scope.isChatGptWidgetEnabled && $scope.widget.type === WidgetType.CHATGPT && !DesignFactory.getIsExportingPage();
    }

    /**
     * Returns true if not executive summary
     * @returns {boolean}
     */
    function canIncludeWidgetHeader() {
        if (!_.isNull($scope.widget.type) && !_.isEmpty($scope.widget.type)) {
            return $scope.widget.type !== $scope.WidgetTypeGrouping.EXECUTIVESUMMARY;
        }

        return true;
    }
}

/**
 * @ngInject
 */
function WidgetDataDisplayController(
    $scope,
    WidgetFactory,
    WidgetBuilderService,
    WidgetBuilderConstants,
    WidgetUtilService,
) {
    $scope.getWidgetFlexClass = getWidgetFlexClass;
    $scope.getChartId = getChartId;
    $scope.widgetType = widgetType;

    var init = function() {
        var widget = $scope.widget;
        var datasource = widget.metadata.data_source;
        // Decide whether or not to show service/category entity info
        $scope.showEntityInfo = widget.is_predefined
            && !$scope.state.isCreating
            && (datasource && datasource.is_of_type_service);
    };

    init();

    /**
     * Determine the flex flow of a widget based on height and width of widget
     * Also, when exporting, in order to fix the issue - wkhtmltohtml doesn't
     * support flex syntax so we use box model
     * @returns {string}
     */
    function getWidgetFlexClass() {
        var $widget = WidgetFactory.$getElement($scope.widget.id, $scope.widget.is_export);
        return $widget.height() < $widget.width() ? 'flex-row widget-box-horizontal' : 'flex-column widget-box-vertical';
    }

    /**
     * Returns appropriate chart id depending if we are building (add/edit mode) or not
     * @returns {string}
     */
    function getChartId() {
        const { widget } = $scope;
        return WidgetBuilderService.isCurrentWidgetInBuildMode(widget.id)
            && !WidgetBuilderService.getIsEditing() // isEditing means not creating a new widget.
                ? WidgetBuilderConstants.PREVIEW_CHART
                : widget.chartId || widget.id;
    }

    function widgetType() {
        var isAm5Widget = WidgetUtilService.isAm5Chart($scope.widget.type, $scope.widget?.metadata?.draw_options?.plot_type);
        return isAm5Widget ? 'am5': $scope.widget.type;
    }
}

/**
 * @ngInject
 */
function WidgetLoaderController(
      $scope,
      gettextCatalog,
      $timeout
) {
    $scope.LoadingState = LoadingState;
    $scope.loadingText = $scope.loadingText || gettextCatalog.getString('Fetching data');
    $scope.size = $scope.size || 30;
    $scope.isResponsive = $scope.isResponsive || false;
    $scope.inverseColor = $scope.inverseColor || false;
    $scope.showOverlay = $scope.showOverlay || false;
    $scope.staticIcon = _.isUndefined($scope.staticIcon) ? true : $scope.staticIcon; // true by default
    $scope.position = $scope.position || 'absolute';
    $scope.top = $scope.top || 0;
    $scope.right = $scope.right || 0;
    $scope.bottom = $scope.bottom || 0;
    $scope.left = $scope.left || 0;
    $scope.addClasses = $scope.addClasses || [];

    $scope.dynamicClasses = {'overlay': $scope.showOverlay, 'responsive': $scope.isResponsive};
    _.each($scope.addClasses, function (cssClass) {
        $scope.dynamicClasses[cssClass] = true;
    });

    // If a threshold is specified, wait for that time before displaying the loader, if competing
    // callback returns first and sets Loading state to DONE, then don't show loader
    if ($scope.threshold) {
        $scope.waitedLongEnough = false;
        $timeout(function () {
            $scope.waitedLongEnough = true;
        }, parseInt($scope.threshold), false);
    }
    else {
        $scope.waitedLongEnough = true;
    }

    $scope.isLoading = function() {
        return $scope.loadingState === true
              || $scope.loadingState === LoadingState.LOADING
              || $scope.loadingState === LoadingState.BUILDING
              || $scope.loadingState === LoadingState.FETCHING;
    }
}
