import { find } from 'lodash';
import { dispatches } from '@/modules/core/app/helpers/store';

export const getters = {
  getData: (state, id) => (state.dataSets[id] ? state.dataSets[id].data : []),
  getTotalCount: (state, id) => (state.dataSets[id] ? state.dataSets[id].data.length : 0),
  getRow: (state, id, rowId) => {
    const { dataSets } = state;
    return dataSets[id]
      ? find(dataSets[id].data, (item) => item[dataSets[id].primaryKeyField] === rowId)
      : {};
  },
  getListParamsState: (state, id) => state.paramsState[id],
  getDatasetUniqueId: (state, id) => (state.dataSets[id] ? state.dataSets[id].uniqueId : null),
  async getUserListSettings(state, id) {
    if (!state.userListSettings[id]) {
      await dispatches.list.loadUserListSettings();
    }
    return state.userListSettings[id];
  },
};
